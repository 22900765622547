<template>
  <v-card
    elevation="0"
    width="606"
    style="margin: 100px auto auto auto; background-color: #fff; padding: 40px"
    class="card-container"
  >
    <div class="card-title">
      <v-icon class="back-arrow" large @click="$router.go(-1)"
        >mdi-chevron-left</v-icon
      >
      Create Payment Link
    </div>
    <v-form ref="form" v-model="isValid">
      <div class="field-title">Product Price *</div>
      <v-text-field
        v-model="link.amount"
        label="Enter product price"
        type="number"
        outlined
        single-line
        required
        :rules="[rules.required, rules.maxAmount, rules.minAmount]"
      ></v-text-field>

      <div class="field-title">Link Validity *</div>
      <v-radio-group v-model="link.linkExpiryType" row>
        <v-radio
          label="Lifetime"
          value="lifetime"
          color="primary"
          class="radio-btn"
        ></v-radio>
        <v-radio
          label="Custom"
          value="custom"
          color="primary"
          class="radio-btn"
        ></v-radio>
      </v-radio-group>

      <div
        v-if="link.linkExpiryType === 'custom'"
        class="date-picker__container"
        :class="{ mb__2: !validDatetime, mb__12: validDatetime }"
      >
        <date-picker
          class="date-picker__container--item"
          style="border: 1.5px solid gray;
              border-radius: 5px;
              height: 54px;
              width: 100%;
              margin-bottom: 12px;"
          v-model="link.linkExpiryDate"
          :disabled-date="
            (date) => date < new Date(new Date().valueOf() - 1000 * 3600 * 24)
          "
          :disabled-time="(date) => date < new Date()"
          :editable="false"
          placeholder="Select a date & time"
          type="datetime"
          valueType="format"
          :open.sync="open"
          @change="handleChange"
          format="YYYY-MM-DD hh:mm a"
          time-title-format="HH:mm"
          :show-second="false"
        >
        </date-picker>
      </div>

      <v-row>
        <div class="error__div">
          <span v-if="!validDatetime">Please select a future time.</span>
        </div>
      </v-row>

      <div class="field-title">Stock Count</div>
      <v-text-field
        v-model="link.transactionCount"
        label="Enter Stock Count"
        outlined
        single-line
        required
        :rules="[rules.maxStockCount, rules.digitOnly]"
      ></v-text-field>

      <div class="field-title">Product Name *</div>
      <v-text-field
        v-model="link.name"
        label="Enter the Product Name"
        outlined
        single-line
        required
        :rules="[rules.required, rules.productNameLength]"
      >
      </v-text-field>

      <div class="field-title">Product Description</div>
      <v-text-field
        v-model="link.description"
        label="Enter the Product Description"
        outlined
        single-line
        :rules="[rules.productDescriptionLength]"
      ></v-text-field>

      <div class="field-title">Product Image</div>
      <div
        v-if="!image"
        class="upload-box"
        style="padding-top: 24px;"
        @click="manuallyOpenFileSelector"
      >
        <!-- No image has been selected -->
        Click to add image
      </div>
      <div v-else class="upload-box">
        <div style="width: 100%; justify-content: flex-end; display: flex;">
          <v-icon class="close-button-icon" color="#000000" @click="clearImage">
            mdi-close
          </v-icon>
        </div>
        <!-- An image has been selected -->
        <img class="image-size" :src="url" />
      </div>
      <v-file-input
        id="fileSelector"
        v-model="image"
        ref="fileSelector"
        show-size
        accept="image/*"
        class="d-none"
        @change="previewImage"
      >
      </v-file-input>
      <div
        class="image-error__div"
        v-if="imageTypeError || imageSize > imageSizeThresholdInBytes"
      >
        <span v-if="imageTypeError" class="size_error_text">
          Image format should be .png, .jpg, or .jpeg.
        </span>
        <span
          v-if="imageSize > imageSizeThresholdInBytes"
          class="size_error_text"
        >
          Image size should be less 10MB.
        </span>
      </div>
      <br />

      <div
        style="color: green; border: 1px solid grey; border-radius: 10px; padding: 10px;"
      >
        <div>
          Collect Customer Information
        </div>

        <br />
        <template
          class="reference__container"
          v-for="(reference, index) in link.references"
        >
          <span :key="index">
            <div class="field-title">{{ reference.fieldName }}</div>

            <span class="opinion__container">
              <v-checkbox
                label="Mandatory field"
                color="primary"
                v-model="reference.required"
              ></v-checkbox>
              <v-icon color="primary" @click="removeReferenceField(index)">
                mdi-close
              </v-icon>
            </span>
          </span>
        </template>
        <div
          style="display: flex; justify-content: center; margin-bottom: 20px;"
        >
          <additional-field
            :additionalFieldCount="link.references.length"
            @addReference="addReference"
          ></additional-field>
        </div>
      </div>
      <br />
    </v-form>

    <v-btn
      color="primary"
      width="100%"
      @click="createPaymentLink"
      :disabled="!isValid || isDisabled || !validDatetime"
      >Create</v-btn
    >
  </v-card>
</template>

<script>
import AdditionalField from "@/components/paymentLinks/components/AdditionalField.vue";
import moment from "moment";
import { INTEGER_ONLY } from "cpp-js-util/CppUtil";

export default {
  name: "CreatePaymentLink",
  components: { AdditionalField },
  data() {
    return {
      isValid: false,
      open: false,
      imageSizeThresholdInBytes: 10485760,
      rules: {
        required: (v) => !!v || "Value is required",
        maxAmount: (v) =>
          (v && v.length < 7) || "Amount must be less than 7 digits",
        maxStockCount: (v) =>
          (v && v.length < 9) || "Amount must be less than 9 digits",
        minAmount: (v) => v > 0 || "Amount must be greater than 0",
        productNameLength: (v) =>
          (v && v.length < 40) || "Product Name should be under 40 character",
        productDescriptionLength: (v) =>
          v.length < 250 || "Product Description should be under 250 character",
        digitOnly: (v) =>
          v.length === 0 || INTEGER_ONLY.test(v) || "Positive integers only",
      },
      link: {
        amount: null,
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        transactionCount: null,
        name: null,
        description: null,
        url: null,
        references: [],
      },
      imageFile: null,
      image: null,
      mimeType: null,
      imageSize: 0,
      fileExtension: "",
    };
  },
  computed: {
    isDisabled() {
      return (
        this.imageSize > this.imageSizeThresholdInBytes || this.imageTypeError
      );
    },
    imageTypeError() {
      return (
        this.fileExtension !== "png" &&
        this.fileExtension !== "jpg" &&
        this.fileExtension !== "jpeg" &&
        this.fileExtension !== ""

      );
    },
    validDatetime() {
      let current = new Date();
      let selected = new Date(this.link.linkExpiryDate);
      return !(this.link.linkExpiryType === "custom" && selected < current);
    },
  },
  methods: {
    clearImage() {
      this.imageFile = null;
      this.image = null;
      this.mimeType = null;
      this.imageSize = 0;
      this.fileExtension = "";
    },
    handleChange(value, type) {
      if (type === "ampm") {
        this.open = false;
      }
    },
    addReference(additionalField) {
      this.link.references.push({ ...additionalField });
    },
    removeReferenceField(index) {
      this.link.references.splice(index, 1);
    },
    manuallyOpenFileSelector() {
      let el = this.$refs.fileSelector.$refs.input;
      el.click();
    },
    async createPaymentLink() {
      this.$feedback.showLoading();
      var formData = new FormData();

      if (this.image) {
        formData.append("image", this.imageFile);
        formData.append("fileExtension", this.fileExtension);
        formData.append("mimeType", this.mimeType);
      }
      formData.append("productName", this.link.name);
      formData.append("linkExpiryType", this.link.linkExpiryType);
      const format1 = "YYYY-MM-DD HH:mm:ss";

      if (this.link.linkExpiryType === "custom") {
        this.link.linkExpiryDate = moment(
          this.link.linkExpiryDate,
          "YYYY-MM-DD hh:mm a"
        ).format(format1);
        formData.append("linkExpiryDate", this.link.linkExpiryDate);
      }
      formData.append("amount", this.link.amount);
      if (this.link.description)
        formData.append("description", this.link.description);
      formData.append("references", JSON.stringify(this.link.references));
      if (this.link.transactionCount) {
        formData.append("stockCount", this.link.transactionCount);
      }
      try {
        let res = await this.$cppClient.post(
          "/payment-link/create",
          formData,
          null,
          true
        );
        let id = res.data.paymentLinkId;
        this.$router.push({ path: `create/success/${id}` });
        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      } finally {
        this.clearForm();
      }
    },
    previewImage() {
      this.imageFile = event.target.files[0];
      this.imageSize = event.target.files[0].size;
      this.url = URL.createObjectURL(this.image);
      this.mimeType = this.image.type;

      let image = new Image();
      image.src = URL.createObjectURL(event.target.files[0]);

      let app = this;
      //Validate the File Height and Width.
      image.onload = function() {
        app.logoHeight = this.height;
        app.logoWidth = this.width;
      };
      this.fileExtension = this.image.name
        .split(".")
        .pop()
        .toLowerCase();
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.image = reader.result.split(",").pop();
      };
    },
  },
};
</script>

<style scoped lang="scss">
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.card-container {
  margin: 100px auto auto auto;
  background-color: #fff;
  padding: 40px;

  @media (max-width: $mobileM) {
    width: 95% !important;
    padding: 20px !important;
  }
}

.back-arrow {
  display: none;

  @media (max-width: $mobileM) {
    display: inline-block;
  }
}

.card-title {
  font-size: 24px;
  margin-bottom: 24px;
  color: #464646;
  font-weight: 600;

  @media (max-width: $mobileM) {
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}

.field-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #464646;
  font-weight: 500;

  @media (max-width: $mobileM) {
    font-size: 14px;
  }
}

.radio-btn {
  border: 1px solid #e2136e;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  width: 150px;

  @media (max-width: $mobileM) {
    width: 140px;
    margin-right: 10px !important;
  }
}

.upload-box {
  border-radius: 4px;
  border: 1px dashed #9a9a9a;
  background: #f3f3f3;
  display: flex;
  width: 526px;
  padding: 2px 24px 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  cursor: pointer;

  @media (max-width: $mobileM) {
    width: 100%;
  }
}
.image-error__div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
}

.error__div {
  width: 310px !important;
  margin-bottom: 12px;
  font-size: 12px;
  @media (max-width: $mobileSm) {
    width: 290px !important;
  }

  span {
    color: #b71c1c !important;
    padding-left: 12px;
  }
}
.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.image-size {
  max-width: 524px;
  max-height: 226px;

  @media (max-width: $mobileM) {
    max-width: 100%;
    max-height: 150px;
  }
}

.input-field {
  width: 310px !important;
  margin-bottom: 10px;

  @media (max-width: $mobileSm) {
    width: 290px !important;
  }
}

.reference__container {
  display: flex;
  align-items: center;

  &--item {
    margin-bottom: 0 !important;
  }
}

.opinion__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
