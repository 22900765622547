<template>
  <v-dialog v-model="dialog" width="unset" persistent style="background-color: #fff;">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" @click="openDialog" color="primary" :disabled="!checkNumberOfReference()">
        + Add Field
      </v-btn>
    </template>
    <v-card class="dialog-card">
      <v-card-title style="display: flex; justify-content: space-between">
        <h2 class="card-title">Add Another Field</h2>
        <v-icon @click="closeDialog">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <span>Field Type</span>
        <v-select :items="fieldTypes" v-model="fieldName" item-text="fieldName" item-value="fieldName" outlined
          single-line></v-select>
        <!-- <span>Field Title</span>
        <v-text-field v-model="ref.fieldTitle" outlined single-line></v-text-field> -->
        <v-checkbox v-model="addedField.required" label="Mark this field mandatory"></v-checkbox>
        <v-btn @click="addReference" color="primary" width="100%">Done</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AdditionalField",
  props: ['additionalFieldCount', 'disabled'],
  created() {
    console.log(this.additionalFieldCount, this.maxNumberOfReferences)
  },
  data() {
    return {
      dialog: false,
      maxNumberOfReferences: 4,
      fieldName: null,
      fieldTypes: [
        { fieldName: "Contact Number", fieldType: "NUMBER" },
        { fieldName: "Name", fieldType: "SMALL_TEXT" },
      ],
      addedField: {
        fieldName: null,
        fieldType: null,
        required: false
      },
    }
  },
  methods: {
    openDialog() {
      this.fetchCustomFields();
      this.addedField.fieldName = null;
      this.addedField.fieldType = null;
      this.addedField.required = false;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    checkNumberOfReference() {
      console.log(this.additionalFieldCount < this.maxNumberOfReferences);
      if (this.disabled) return false;
      return this.additionalFieldCount < this.maxNumberOfReferences;
    },
    addReference() {
      this.addedField.fieldName = this.fieldName;
      // console.log(this.fieldTypes);
      this.addedField.fieldType = (this.fieldTypes.find(obj => { return obj.fieldName === this.fieldName })).fieldType;
      // console.log(this.fieldTypes);
      this.$emit('addReference', this.addedField);
      // console.log(this.fieldTypes);
      this.closeDialog();
    },
    async fetchCustomFields() {
      this.isLoading = true;
      this.$feedback.showLoading();
      try {
        const list = await this.$cppClient.post("payment-link/custom-fields/get", {})
        console.log(list.data);
        this.fieldTypes = list.data;
        this.payload.nextToken = list.data.nextToken;
        this.isLoading = false;
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false;
      this.$feedback.hideLoading();
    }
  }
}
</script>

<style scoped>
.card-title {
  color: #464646;
  font-size: 18px;
  font-weight: 600;
}

.dialog-card {
  padding: 0;
  width: 300px;
}

@media screen and (min-width: 425px) {
  .dialog-card {
    padding: 8px;
    width: 372px;
  }
}

@media screen and (min-width: 768px) {
  .card-title {
    font-size: 24px;
  }

  .dialog-card {
    padding: 16px;
    width: 481px;
  }
}
</style>